export const PUBLIC_SUPABASE_URL = "https://gampynikfsrnysutgahc.supabase.co";
export const PUBLIC_SUPABASE_ANON_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdhbXB5bmlrZnNybnlzdXRnYWhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTIwOTcyMTIsImV4cCI6MjAwNzY3MzIxMn0.YIU-CQ9Ri_66ZEunExURBTLGH60l6A4wX5GcfIwlNHI";
export const PUBLIC_TRIBE_CLOUDFRONT_URL =
  "https://d3phs16h32wp7b.cloudfront.net";

export const BANGALORE = {
  latitude: 12.931793,
  longitude: 77.635272,
  label: "Bangalore",
};


export const PUBLIC_PROMPT0_API_KEY = "p0-41SGZ3eCuzGmQ6y9KwYEg";
export const PUBLIC_IPINFO_TOKEN = "87e9fa11459da6";
export const PUBLIC_GOOGLE_MAPS_API_KEY =
  "AIzaSyDbxK67sVJvLaGbkUO5sv_c8vhZf0FN0pk";
